import { Component, computed, effect, signal } from '@angular/core';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { StyleDto } from '@swagger/humanresources';
import { NgIf } from '@angular/common';
import { debounceTime, merge, Observable, of, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { isEqual } from 'lodash';
import { hexColorValidator } from '@shared/utils';



type StyleFormGroup = FormGroup<{
  key: FormControl<string>;
  backgroundColor: FormControl<string>;
  textColor: FormControl<string>;
  borderColor: FormControl<string>;
}>;

@Component({
  selector: 'style-control',
  templateUrl: './style-control.component.html',
  styleUrls: ['./style-control.component.scss'],
  standalone: true,
  imports: [UiFormFieldModule, ReactiveFormsModule, NgIf],
})
export class StyleControlComponent extends AbstractDynamicFormControl<
  StyleDto[]
> {
  formArray = new FormArray<StyleFormGroup>([]);

  formArrayValue$ = this.formArray.valueChanges;

  $formArrayValue = toSignal(this.formArrayValue$.pipe(debounceTime(250)));

  control$ = toObservable(this.$control);

  controlValue$ = this.control$.pipe(
    switchMap((control) => merge(of(control.value), control.valueChanges))
  ) as Observable<StyleDto[]>;

  $controlValue = toSignal(this.controlValue$);

  $keys = computed(() => this.$controlValue()?.map((style) => style.key));

  _$selectedKey = signal<string | null>(null);

  $selectedKey = computed(() => {
    const selectedKey = this._$selectedKey();
    const keys = this.$keys();

    if (!selectedKey || !keys.some((key) => key === selectedKey)) {
      return keys.find((key) => key === 'Standard' || key === 'default') || keys[0];
    }

    return selectedKey;
  });

  _original: any;

  onControlValueChangeEffect = effect(() => {
    const controlValue = this.$controlValue();

    if (!Array.isArray(controlValue)) {
      this.formArray.clear();
      return;
    }

    for (let style of controlValue) {
      const control = this.formArray.controls.find(
        (c) => c.value.key === style.key
      );

      if (control) {
        this.patchFormGroup(control, style);
      } else {
        this.formArray.push(this.createFormGroup(style));
      }
    }

    this._original = this.formArray.value;
  });

  onFormArrayValueChangeEffect = effect(() => {
    const formArrayValue = this.$formArrayValue();

    if (isEqual(formArrayValue, this._original)) {
      return;
    }

    const styles: StyleDto[] = [];

    if (!Array.isArray(formArrayValue)) {
      return;
    }

    for (let value of formArrayValue) {
      let style = this.$controlValue().find((style) => style.key === value.key);
      style = this.mapToStyleDto(structuredClone(style), value);
      styles.push(style);
    }

    this.control.setValue(styles);
    this.control.setErrors(this.getErrors());
  });

  getErrors() {
    if (this.formArray.valid && this.formArray.controls?.length) {
      return null;
    }

    const errors: ValidationErrors = {};

    for (let group of this.formArray.controls) {
      if (group.invalid) {
        for (let controlName in group.controls) {
          const control = group.controls[controlName];
          if (control.errors) {
            errors[`${group.value.key}.${controlName}`] = control.errors;
          }
        }
      }
    }

    return errors;
  }

  createFormGroup(style: StyleDto): StyleFormGroup {
    return new FormGroup({
      key: new FormControl(style.key),
      backgroundColor: new FormControl(style.css.background.color, [
        Validators.minLength(3),
        Validators.maxLength(6),
        hexColorValidator,
      ]),
      textColor: new FormControl(style.css.text.color, [
        Validators.minLength(3),
        Validators.maxLength(6),
        hexColorValidator,
      ]),
      borderColor: new FormControl(style.css.boxModel.borderColor, [
        Validators.minLength(3),
        Validators.maxLength(6),
        hexColorValidator,
      ]),
    });
  }

  patchFormGroup(formGroup: StyleFormGroup, style: StyleDto) {
    formGroup.patchValue({
      backgroundColor: style.css?.background?.color,
      textColor: style.css?.text?.color,
      borderColor: style.css?.boxModel?.borderColor,
    });
  }

  mapToStyleDto(target: StyleDto, value: StyleFormGroup['value']): StyleDto {
    target.key = value.key;

    target.css.background.color = value.backgroundColor;
    target.css.text.color = value.textColor;
    target.css.boxModel.borderColor = value.borderColor;

    return target;
  }
}
