import { Component, ChangeDetectionStrategy } from '@angular/core';
import { DynamicFormControlTemplateDirective } from '../../dynamic-form-control-template.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';
import { DynamicFormControlMetadata } from '@shared/dynamic-form';

@Component({
  selector: 'dynamic-form-text-control',
  templateUrl: 'text-control.component.html',
  styleUrls: ['text-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormTextControlComponent,
    },
  ],
})
export class DynamicFormTextControlComponent extends AbstractDynamicFormControl {


  getValueChangeEvent({meta, event}:{meta:DynamicFormControlMetadata<any>; event:Event}) {
    const valueChangeAction = meta.actions?.find((a) => a.key === 'keyup');
    if(event) {
      return valueChangeAction?.actionCallbackFn(event);
    }
  }

}
